//关键比率
import axios from '@/axios/globalaxios'

export const findKeyRatios = data =>{
    return axios({
        method: 'post',
        url: '/tradelab/course/findKeyRatios',
        data: data
    })
}

export const findAssetAllocation = data =>{
    return axios({
        method: 'post',
        url: '/tradelab/course/findAssetAllocation',
        data: data
    })
}
export const findRiskAnalysis = data =>{
    return axios({
        method: 'post',
        url: '/tradelab/course/findRiskAnalysis',
        data: data
    })
}
export const findHeavyweightSecurities = data =>{
    return axios({
        method: 'post',
        url: '/tradelab/course/findHeavyweightSecurities',
        data: data
    })
}
export const findIndustryAllocation = data =>{
    return axios({
        method: 'post',
        url: '/tradelab/course/findIndustryAllocation',
        data: data
    })
}